<template>
  <div class="bank-book-withdraw" :class="{skeleton: !state.loaded }">
    <div class="section pb-2" v-if="items && items.includes('label')">
      <div class="subject">
        <span>출금 계좌</span>
      </div>
    </div>
    <div class="section">
      <div class="subject">
        <span>계좌주</span>
      </div>
      <div class="value font-md">
        <span>
          <b>{{ state.account.investorName || "(없음)" }}</b>
        </span>
        <button class="btn btn-bordered-secondary font-sm" @click="openWithdrawModal()" v-if="state.account.withdrawAccountNum && items && items.includes('withdraw')">출금하기</button>
      </div>
    </div>
    <div class="section">
      <div class="subject">
        <span>계좌번호</span>
      </div>
      <div class="value font-md">
        <span>
          <b>{{ $definitions.codes.obj.bank[state.account.withdrawAccountCode] }} </b>
          <span>{{ state.account.withdrawAccountNum || "(없음)" }}</span>
        </span>
        <button class="btn btn-bordered-secondary font-sm" @click="openBankAccountModal()" v-if="items && items.includes('change')">
          {{ state.account.withdrawAccountNum ? "변경하기" : "등록하기" }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "componentBankBookWithdraw";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    callback: Function,
    items: Array,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
      load();
    });

    const state = reactive({
      loaded: false,
      account: {
        investorSeq: "",
        investorName: "",
        virtualAccountNum: "",
        withdrawAccountCode: "",
        withdrawAccountNum: "",
      }
    });

    const openWithdrawModal = () => {
      store.commit("openModal", {
        name: "AccountWithdraw",
        params: {
          investorSeq: state.account.investorSeq,
          investorName: state.account.investorName,
          investorTel: state.account.investorTel,
          withdrawAccountCode: state.account.withdrawAccountCode,
          withdrawAccountNum: state.account.withdrawAccountNum,
        },
        callback: "refresh"
      });
    };

    const onCloseBankAccountModal = (params) => {
      const investorName = state.account.investorName || store.state.account.memberName;
      const withdrawAccountCode = params.withdrawAccountCode;
      const withdrawAccountNum = params.withdrawAccountNum;

      if (typeof props.callback === "function") {
        props.callback({
          investorSeq: state.account.investorSeq,
          investorName,
          virtualAccountNum: state.account.virtualAccountNum,
          withdrawAccountCode,
          withdrawAccountNum
        });
      } else {
        state.account.investorName = investorName;
        state.account.withdrawAccountCode = withdrawAccountCode;
        state.account.withdrawAccountNum = withdrawAccountNum;
        sync();
      }
    };

    const openBankAccountModal = () => {
      store.commit("openModal", {
        name: "BankAccount",
        params: {
          investorName: state.account.investorName || store.state.account.memberName,
          withdrawAccountCode: state.account.withdrawAccountCode,
          withdrawAccountNum: state.account.withdrawAccountNum,
        },
        callback: `${component.name}.onCloseBankAccountModal`
      });
    };

    const sync = () => {
      emit("update:withdrawAccountCode", state.account.withdrawAccountCode);
      emit("update:withdrawAccountNum", state.account.withdrawAccountNum);
    };

    const load = () => {
      state.account.investorName = "Wait a moment";
      state.account.withdrawAccountNum = "Wait a moment";

      state.loaded = false;
      if (store.state.investor.investorSeq) {
        http.get(`/api/investor/${store.state.investor.investorSeq}`).then(({data}) => {
          state.loaded = true;
          state.account = data.body.investor;
          sync();
        });
      } else {
        http.get(`/api/members/${store.state.account.memberSeq}/account`).then(({data}) => {
          state.loaded = true;
          state.account.investorName = store.state.account.memberType === "C" ? data.body.corporateName : data.body.memberName;
          state.account.withdrawAccountNum = "";
        });
      }
    };

    return {component, state, openWithdrawModal, openBankAccountModal, load, onCloseBankAccountModal};
  },
});
</script>

<style lang="scss" scoped>
.bank-book-withdraw {
  padding: $px10 $px25;
  border: $px1 solid #eee;
  background: $colorBackground;
  border-radius: $px4;

  .section {
    padding: $px15 0;

    .subject {
      margin-bottom: $px4;
    }

    .value {
      b, span {
        vertical-align: middle;
      }

      .btn {
        margin-top: $px-15;
        float: right;
        width: $px90;
        padding: $px10 0;
      }
    }
  }

  &.skeleton {
    .section {
      .subject > span {
        @include skeleton
      }

      .value {
        > b, > span, > button {
          @include skeleton
        }
      }
    }
  }

  @media(max-width: 767px) {
    .section .value .btn {
      float: none;
      margin-top: $px15;
      width: 100%;
    }
  }
}
</style>
export class FormDataCustom extends FormData {
  constructor(values) {
    super();

    if (typeof values === "object" && Object.keys(values).length) {
      for (let name in values) {
        this.append(name, values[name]);
      }
    }
  }
}